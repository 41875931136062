<template>
  <div>
    
   <h1>お問い合わせ</h1>
   
   <router-link @click.native="scrollToTop" to="qa">Ｑ＆Ａ</router-link> | 
   <router-link @click.native="scrollToTop" to="contact">お問い合わせ</router-link>

<!--   <router-view></router-view>-->

   
   <div class="contactform" v-if="view!='complete'">
     <div v-if="view=='confirm'">

       <strong>まだ送信は完了していません。</strong><br>以下の内容で送信してよろしいですか？
       <div class="bns">
           <button @click="prev">前に戻る</button>
           <button @click="submit">この内容を送信</button>
           </div>

           
          <table>
            <tbody>
              <tr>
                <th colspan="2" class="formListTh3">お問合せフォーム</th>
              </tr>
              <tr>
                <th align="right" class="formListTh">お名前：</th>
                <td align="left" class="formListTd">{{name}}</td>
              </tr>
              <tr>
                <th align="right" class="formListTh">お問合せ内容</th>
                <td align="left" class="formListTd">{{subject}}</td>
              </tr>
              <tr>
                <th align="right" class="formListTh2">お手紙番号：</th>
                <td align="left" class="formListTd">{{number}}</td>
              </tr>
              <tr>
                <th align="right" class="formListTh">メールアドレス：</th>
                <td align="left" class="formListTd">{{email}}</td>
              </tr>
            <tr>
                <th align="right" class="formListTh">お問合せメッセージ：</th>
                <td align="left" class="formListTd">{{message}}</td>
              </tr>
            </tbody>
          </table> 

          
       <div class="bns">
           <button @click="prev">前に戻る</button>
           <button @click="submit">この内容を送信</button>
           </div>

     </div>
     <div v-else>
          <table>
            <tbody>
              <tr>
                <th colspan="2" class="formListTh3">お問合せフォーム</th>
              </tr>
              <tr>
                <th align="right" class="formListTh">お名前：</th>
                <td align="left" class="formListTd"><input v-model="name" name="name" type="text" class="fm1" value="" size="20">
                *必須</td>
              </tr>
              <tr>
                <th align="right" class="formListTh">お問合せ内容</th>
                <td align="left" class="formListTd">
                
                                <input type="radio" v-model="subject" name="msgtype" id="msgtype1" value="注文内容の変更に関するお問い合わせ">
                                    <label for="msgtype1">注文内容の変更に関するお問い合わせ <!--<br>
                  <span style="color:red">⇒(ご注文のご変更の受付は終了しました)</span>		--></label><br>
                  <input v-model="subject" type="radio" id="msgtype2" name="msgtype" value="その他のお問合せ">
                  <label for="msgtype2">その他のお問合せ</label>
                                    
                  </td>
              </tr>
              <tr>
                <th align="right" class="formListTh2">お手紙番号：</th>
                <td align="left" class="formListTd">例：SW22-107901<br><input v-model="number" name="order_no" type="text" class="fm1" value="" size="20"></td>
              </tr>
              <tr>
                <th align="right" class="formListTh">メールアドレス：</th>
                <td align="left" class="formListTd"><input v-model="email" name="email" type="text" class="fm1" value="" size="20">
                *必須</td>
              </tr>
			<tr>
                <th align="right" class="formListTh">メールアドレス（確認）：</th>
                <td align="left" class="formListTd"><input v-model="email_c" name="email2" type="text" class="fm1" value="" size="20">
                *必須</td>
            </tr>
            <tr>
                <th align="right" class="formListTh">お問合せメッセージ：</th>
                <td align="left" class="formListTd">*必須<br><textarea v-model="message" name="msg" cols="30" rows="6" class="fm1"></textarea></td>
              </tr>
            </tbody>
          </table> 
           <button @click="confirm">送信内容の確認</button>
          </div>     
       
</div>
<div class="contactform_end" v-else>
  ありがとうございます。送信を完了しました。
</div>



    <dialogalert
      v-model="visible_dialog"
      :title="dialog_title"
      :info="dialog_info"
      :button_ok="dialog_bn_ok"
      :button_cancel="dialog_bn_cancel"
      :ok_fn="dialog_ok_fn"
    />


  </div>
</template>

<script>
export default  {
  beforeRouteLeave(to, from, next) {
    if(this.name || this.subject || this.number | this.email || this.message){
      if(window.confirm("編集中のものは保存されませんが、よろしいですか？")){
          next();
        }else{
          return;
        }
    }else{
      next();
    }
  },
  data:function(){
    return {
      name:"",subject:"",number:"",email:"",email_c:"",message:"",
      view:"edit"
    }
  },
    created: function() { 
      this.$parent.middle = 2; 
    window.addEventListener("beforeunload", this.confirmSave);
      },
  destroyed () {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
      methods:{
    confirmSave (event) {
      if(this.name || this.subject || this.number | this.email || this.message)
      event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
    },
        prev:function(){      
              this.view="edit";   
        },
        confirm:function(){
 
          this.fetch("contact",{name:this.name,subject:this.subject,number:this.number,email:this.email,email_c:this.email_c,message:this.message,flow:"check"}).then((data)=>{
            if(data.error){
               this.opendialog({title:"送信エラーです。",info:data.error, bn_ok:"OK"});

            }else{  
              this.view="confirm";
              //alert("ありがとうございます。送信を完了しました。")
              //this.view="complete";
            }
          }) 
        },
        submit:function(){
          this.view="complete";
          this.fetch("contact",{name:this.name,subject:this.subject,number:this.number,email:this.email,email_c:this.email_c,message:this.message,flow:"submit"}).then((data)=>{
            if(data.error){

               this.opendialog({title:"送信エラーです。",info:data.error,
            bn_ok:"OK"});

            }else{

            //alert("ありがとうございます。送信を完了しました。")
              this.view="complete";
            }
          })
        }
      }
      }
</script>

<style scoped lang="scss">
.tinfo{
    padding: 1em 0;
    font-size: .8em;
}
h1{
  margin-top: 1em;
}
.contactform_end{
  padding: 7em 0;
  text-align: center;
}
.contactform{
  padding-bottom:2em ;
  table{
  width:800px;
  max-width: 95%;
  margin: 0 auto;
  .formListTh3{
    background: #D11E1E;
    color: #fff;
  }
  th{
    padding: .7em;
    background: rgb(226, 226, 226);
    color: rgb(49, 49, 49);
    width: 13em;
  }
  td{
    padding: .7em;
    flex: 1;
  }
  }
}

@media (max-width: 768px) {
.contactform{
  table{
    width: 100%;
  th{display: block;
    width: 100%;
    text-align: left;
  }
  td{display: block;
    width: 100%;
  }
  }
}
}
</style>